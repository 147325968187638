import {classNames} from "primereact/utils";
import React, {ReactNode} from "react";

interface CustomErrorProps {
    children?: ReactNode;
    className?: string;
}

export const CustomError: React.FC<CustomErrorProps> = ({children, className}) => {
    return (
        <div className={classNames("custom-page-error", className)}>
            {children ? children : null}
        </div>
    );
};
