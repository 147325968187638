import {API} from "../ApiService";
import {APIError, AuthProvider, ICheckAuthResponse} from "../interfaces";
import {SessionService} from "../SessionService";

const UnauthorizeCheckAuthResponse: ICheckAuthResponse = {
    isLoggedIn: false,
    data: null,
    errors: [],
    sessionToken: null,
    provider: null
};

export async function checkAuth(): Promise<ICheckAuthResponse> {
    const clientSessionToken = SessionService.getToken();
    const clientProvider = SessionService.getProvider();
    let sessionToken = null;
    let provider: AuthProvider | null = null;

    let isLoggedIn = false;
    let data = null;
    let errors: APIError[] = [];
    if (clientSessionToken) {
        try {
            const userData = await API.getUserData(clientSessionToken, true);

            if (userData.errors.length === 0 && userData.data) {
                isLoggedIn = true;
                data = userData.data;
                sessionToken = clientSessionToken;
                provider = clientProvider;
            } else {
                errors = userData.errors;
            }

            return {
                sessionToken,
                provider,
                isLoggedIn,
                data,
                errors
            };
        } catch (e) {
            return UnauthorizeCheckAuthResponse;
        }
    }

    return UnauthorizeCheckAuthResponse;
}
