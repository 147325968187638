import {ReactNode} from "react";
import {Navigate} from "react-router-dom";
import {observer} from "mobx-react";
import {useRootStore} from "../../store/RootStore";
import {PageAuthPreloader} from "../LoadingComponents/PageAuthPreloader";

interface PublicRouteProps {
    children: ReactNode;
    restricted?: boolean;
}

const PublicRoute = observer(({children, restricted = false}: PublicRouteProps) => {
    const {userStore, routeStore} = useRootStore();
    const {isLoading, isInitializing, isLoggedIn} = userStore;

    if (isInitializing || isLoading) {
        return <PageAuthPreloader />;
    }

    // Если страница restricted (login/reset/change-password) и пользователь авторизован
    if (restricted && isLoggedIn) {
        return <Navigate to={routeStore.intendedRoute || "/"} replace />;
    }

    return <>{children}</>;
});

export default PublicRoute;
