import {SuggestionsDictionary} from "../constants";
import {ClientValidationRule} from "../services/ClientValidationService";

export type Suggestion = {
    valid: boolean;
    message: string;
    key: ClientValidationRule;
};

export const usePasswordSuggestions = (
    value: string,
    dictionary: SuggestionsDictionary
): Suggestion[] => {
    const suggestions: Suggestion[] = dictionary.map(item => {
        const key = item.key;
        const message = item.message;
        const validator = item.validator;
        let valid = false;

        if (validator) {
            if (validator[key] && typeof validator[key] === "function") {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                const result = validator[key](value);
                valid = result === true;
            }
        }

        return {
            valid,
            message,
            key
        };
    });

    return suggestions;
};
