import {useNavigate} from "react-router-dom";
import {Header5} from "../../components/common";
import {MainButton} from "../../components/common/Buttons";

export const PleaseRegister = () => {
    const navigate = useNavigate();

    return (
        <>
            <div className="flex flex-column justify-content-center align-items-center">
                <div>
                    <Header5>To continue booking a ticket, please login or register.</Header5>
                </div>
                <div>
                    <MainButton
                        type="button"
                        label={"Login or Register"}
                        onClick={() => navigate("/login")}
                    />
                </div>
            </div>
        </>
    );
};
