import React from "react";
import {classNames} from "primereact/utils";

type ErrorMessageData = {
    id: string;
    value: string;
};

export type FieldErrorProps = {
    className?: string;
    children?: React.ReactNode;
    baseClassName?: string;
    id?: string;
};

interface ErrorMessageProps {
    errors: ErrorMessageData[] | string | undefined;
    className?: string;
}

export const FieldError = ({
    className,
    children,
    baseClassName = "flex justify-content-between w-full align-items-center p-informer",
    id
}: FieldErrorProps) => {
    return (
        <small id={id ? id : ""} className={classNames(baseClassName, className)}>
            {children ? children : null}
        </small>
    );
};

export const FieldErrorMessage: React.FC<ErrorMessageProps> = ({errors, className}) => {
    if (!errors || errors.length === 0) return null;
    if (Array.isArray(errors)) {
        const errorTexts = errors.map(error => error.value);
        return <span className={classNames("p-error", className)}>{errorTexts.join(", ")}</span>;
    } else {
        return <span className={classNames("p-error", className)}>{errors}</span>;
    }
};
