import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {useRootStore} from "../../store/RootStore";
import {PleaseRegister} from "./PleaseRegister";
import {useEffect, useState} from "react";
import {PageAuthPreloader} from "../../components/LoadingComponents/PageAuthPreloader";
import {ContentB1} from "../../components/common";
import {API} from "../../services/ApiService";
import {LandingLoginFormSuccess} from "../../components/formComponents";

const pageId = PagesId.Buy;

const ErrorBlock = () => {
    return <ContentB1>Something went wrong, please try again later.</ContentB1>;
};

const BuyPage = observer(() => {
    const {userStore, bookingStore} = useRootStore();

    const [loading, setLoading] = useState(true);
    const [isError, setIsError] = useState(false);

    useEffect(() => {
        if (userStore.isLoggedIn && bookingStore.lastBooking) {
            const book = async () => {
                if (userStore.isLoggedIn && bookingStore.lastBooking) {
                    setLoading(true);
                    try {
                        const result = await API.bookTicket(
                            {
                                flight_id: bookingStore.lastBooking.flight_id,
                                seats: bookingStore.lastBooking.seats
                            },
                            userStore.sessionToken
                        );

                        if (result.errors.length > 0) {
                            setIsError(true);
                        }
                    } catch (error) {
                        setIsError(true);
                    } finally {
                        setLoading(false);
                    }
                }
            };

            book();
        }

        return () => {
            bookingStore.setLastBooking(null);
        };
    }, []);

    return (
        <PageContent pageId={pageId}>
            {userStore.isLoggedIn ? (
                loading ? (
                    <PageAuthPreloader />
                ) : isError ? (
                    <ErrorBlock />
                ) : (
                    <LandingLoginFormSuccess />
                )
            ) : (
                <PleaseRegister />
            )}
        </PageContent>
    );
});

export default BuyPage;
