import {classNames} from "primereact/utils";
import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {ReactComponent as FormHeaderIconDone} from "../../icons/icon-form-header-done.svg";
import {ReactComponent as CloseButtonIcon} from "../../icons/icon-close.svg";
import {ContentB1, Header2, Header3} from "../common";
import {MainButton, SecondaryButton} from "../common/Buttons";
import {Button} from "primereact/button";

type FormProviderLaterLinkProps = {
    to?: string;
    className?: string;
    text?: string;
    disabled?: boolean;
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
};

export const FormProvideLaterLink = ({
    to = "/",
    text = "Provide later",
    className,
    disabled = false,
    onClick
}: FormProviderLaterLinkProps) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        // Если передан onClick, вызываем его
        if (onClick) {
            onClick(event);
        }

        // Если onClick был передан и не был остановлен preventDefault, не делаем переход
        if (onClick && event.defaultPrevented) {
            return;
        }

        // Если disabled, предотвращаем переход
        if (disabled) {
            event.preventDefault();
        }
    };

    return (
        <div className={classNames("p-informer p-informer--align-right", className)}>
            <Link
                to={disabled ? "#" : to} // Если disabled, блокируем переход
                className={classNames("p-informer__link", {"p-informer__link--disabled": disabled})}
                onClick={handleClick}
                aria-disabled={disabled} // Для доступности добавляем атрибут
                tabIndex={disabled ? -1 : 0} // Если disabled, убираем возможность фокуса
            >
                {text}
            </Link>
        </div>
    );
};

type FormHeaderIconProps = {
    icon?: ReactNode;
    className?: string;
};

export const FormHeaderIcon = ({icon, className}: FormHeaderIconProps) => {
    return <div className={classNames("form-header-icon", className)}>{icon ? icon : null}</div>;
};

export const LoginFormSuccess = () => {
    const {translate} = useSafeTranslations();
    return (
        <>
            <FormHeaderIcon icon={<FormHeaderIconDone />} />
            <Header3>{translate("thank-you-text")}</Header3>
            <div className="field col-10 p-0">
                <div className="content-b3 form-description">
                    {translate("new-user-created-text")}
                </div>
            </div>
            <div className="field col-10 p-0 mt-5">
                <div className="content-b3 p-instruction form-description">
                    Please <b>check your email</b> and follow the instructions in the email.
                </div>
            </div>
        </>
    );
};

export const LandingLoginFormSuccess = () => {
    const {translate} = useSafeTranslations();
    return (
        <>
            <FormHeaderIcon icon={<FormHeaderIconDone />} />
            <Header3>{translate("thank-you-text")}</Header3>
            <div className="field col-10 p-0">
                <div className="content-b3 form-description">
                    {/* {translate("new-user-created-text")} */}
                </div>
            </div>
            <div className="field col-10 p-0 mt-2">
                <div className="content-b3 p-instruction form-description">
                    Your ticket order has been accepted. Please wait for instructions via email.
                </div>
            </div>
        </>
    );
};

export const Header2Form = ({
    children,
    className
}: {
    children: React.ReactNode;
    className?: string;
}) => {
    return <Header2 className={classNames("header2--form", className)}>{children}</Header2>;
};

export const FormHeader = ({
    children,
    className
}: {
    children?: React.ReactNode;
    className?: string;
}) => {
    return (
        <div className={classNames("form__header", className)}>{children ? children : null}</div>
    );
};

export const ContentB1Form = ({
    children,
    className
}: {
    children?: React.ReactNode;
    className?: string;
}) => {
    return (
        <ContentB1 className={classNames("content-b1--form", className)}>
            {children ? children : null}
        </ContentB1>
    );
};

export const FormContent = ({
    children,
    className
}: {
    children?: React.ReactNode;
    className?: string;
}) => {
    return (
        <div className={classNames("form__content", className)}>{children ? children : null}</div>
    );
};

export const FormFooter = ({
    children,
    className
}: {
    children?: React.ReactNode;
    className?: string;
}) => {
    return (
        <div className={classNames("form__footer", className)}>{children ? children : null}</div>
    );
};

export const AccountFormFooter = ({
    onCancelClick,
    onSaveClick,
    disabled
}: {
    onCancelClick?: () => void;
    onSaveClick?: () => void;
    disabled?: boolean;
}) => {
    const {translate} = useSafeTranslations();
    return (
        <FormFooter className={"form__footer--row-directed form__footer--align-right"}>
            <SecondaryButton
                type={"button"}
                disabled={disabled}
                label={translate("cancel-button-text")}
                onClick={onCancelClick}
            />
            <MainButton type={"submit"} disabled={disabled} label={translate("save-button-text")} />
        </FormFooter>
    );
};

type CloseFormButtonProps = {
    onClick?: () => void;
    className?: string;
};

export const CloseFormIconButton = ({onClick, className}: CloseFormButtonProps) => {
    return (
        <Button
            type={"button"}
            rounded
            onClick={onClick}
            className={classNames("p-button-icon-only form__close-button", className)}
        >
            <CloseButtonIcon />
        </Button>
    );
};
