import {ENV_CONST} from "../constants";
import {AuthProvider} from "./interfaces";
const {IS_DEV_MODE, LS_SALT, LS_SESSION_KEY} = ENV_CONST;

export class SessionService {
    private static readonly TOKEN_KEY = LS_SESSION_KEY;
    private static readonly LS_SALT = LS_SALT;
    private static readonly PROVIDER_KEY = "provider";

    private static encrypt(data: string): string {
        const encrypted = data
            .split("")
            .map((char, index) => {
                const keyChar = this.LS_SALT[index % this.LS_SALT.length];
                return String.fromCharCode(char.charCodeAt(0) ^ keyChar.charCodeAt(0));
            })
            .join("");

        return btoa(encrypted);
    }

    private static decrypt(encryptedData: string): string {
        const decoded = atob(encryptedData);
        return decoded
            .split("")
            .map((char, index) => {
                const keyChar = this.LS_SALT[index % this.LS_SALT.length];
                return String.fromCharCode(char.charCodeAt(0) ^ keyChar.charCodeAt(0));
            })
            .join("");
    }

    static setToken(token?: string | null): void {
        if (!token) return;
        try {
            const data = {
                token,
                timestamp: Date.now()
            };

            const encrypted = this.encrypt(JSON.stringify(data));
            localStorage.setItem(this.TOKEN_KEY, encrypted);
        } catch {
            this.removeToken();
        }
    }

    static setProvider(provider: AuthProvider | null): void {
        if (!provider) return;

        try {
            const data = {
                provider,
                timestamp: Date.now()
            };

            const encrypted = this.encrypt(JSON.stringify(data));
            localStorage.setItem(this.PROVIDER_KEY, encrypted);
        } catch {
            this.removeProvider();
        }
    }

    static getProvider(): AuthProvider | null {
        try {
            const encrypted = localStorage.getItem(this.PROVIDER_KEY);
            if (!encrypted) return null;

            const decrypted = this.decrypt(encrypted);
            const data = JSON.parse(decrypted);

            return data.provider;
        } catch {
            return null;
        }
    }

    static getToken(): string | null {
        try {
            const encrypted = localStorage.getItem(this.TOKEN_KEY);
            if (!encrypted) return null;

            const decrypted = this.decrypt(encrypted);
            const data = JSON.parse(decrypted);

            return data.token;
        } catch {
            this.removeToken();
            return null;
        }
    }

    static removeToken(): void {
        localStorage.removeItem(this.TOKEN_KEY);
    }

    static removeProvider(): void {
        localStorage.removeItem(this.PROVIDER_KEY);
    }
}

if (IS_DEV_MODE) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    window.SessionServise = SessionService;
}
