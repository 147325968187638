import React, {
    createContext,
    useState,
    ReactNode,
    ComponentType,
    useContext,
    useRef,
    useEffect
} from "react";
import {Dialog} from "primereact/dialog";

interface DialogContextProps {
    openDialog: (Component: ComponentType<any>, props?: any) => void;
    closeDialog: () => void;
}

const DialogContext = createContext<DialogContextProps | undefined>(undefined);

export const useDialogContext = () => {
    const context = useContext(DialogContext);
    if (!context) {
        throw new Error("useDialogContext must be used within a DialogProvider");
    }
    return context;
};

export const DialogProvider: React.FC<{children: ReactNode}> = ({children}) => {
    const [visible, setVisible] = useState(false);
    const [Content, setContent] = useState<ComponentType<any> | null>(null);
    const [contentProps, setContentProps] = useState<any>(null);

    const rootElementRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        rootElementRef.current = document.getElementById("root");
    }, []);

    const openDialog = (Component: ComponentType<any>, props?: any) => {
        setContent(() => Component);
        setContentProps(props || null);
        setVisible(true);
    };

    const closeDialog = () => {
        setVisible(false);
        setContent(null);
        setContentProps(null);
    };

    return (
        <DialogContext.Provider value={{openDialog, closeDialog}}>
            {children}
            <Dialog
                visible={visible}
                onHide={closeDialog}
                modal
                content={() => Content && <Content {...contentProps} />}
                closable={false}
                header={null}
                footer={null}
                appendTo={rootElementRef.current}
            />
        </DialogContext.Provider>
    );
};
