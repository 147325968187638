import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {NewPasswordForm} from "../../components/forms/NewPasswordForm";
import {PageAuthPreloader} from "../../components/LoadingComponents/PageAuthPreloader";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {API} from "../../services/ApiService";
import {InvalidResetToken} from "../../components/customErrors";

const pageId = PagesId.ChangePassword;

const ChangePasswordPage = observer(() => {
    const {token} = useParams<{token: string}>();
    const [isValidToken, setIsValidToken] = useState<boolean>(false);
    const [isTokenChecking, setIsTokenChecking] = useState<boolean>(true);

    useEffect(() => {
        const validateToken = async () => {
            if (!token) return;
            try {
                const result = await API.validateResetToken(token);
                setIsValidToken(result);
            } catch {
                setIsValidToken(false);
            } finally {
                setIsTokenChecking(false);
            }
        };
        validateToken();
    }, [token]);

    return (
        <PageContent pageId={pageId}>
            {!token || (!isTokenChecking && !isValidToken) ? (
                <InvalidResetToken />
            ) : isTokenChecking ? (
                <PageAuthPreloader />
            ) : (
                <NewPasswordForm />
            )}
        </PageContent>
    );
});

export default ChangePasswordPage;
