import {observer} from "mobx-react";
import {useForm} from "react-hook-form";
import useFormSubmit, {ApiCall} from "../../customHooks/useFormSubmit";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {API} from "../../services/ApiService";
import {INewPasswordResponse, IProfilePasswordData} from "../../services/interfaces";
import {ContentB1, Header4, Header5} from "../common";
import {AccountFormFooter, FormContent, FormHeader} from "../formComponents";
import {
    Field,
    PasswordFieldControlled,
    PasswordSuggestionsFooter,
    TextField
} from "../fieldComponents";
import {
    ENV_CONST,
    passwordLengthRule,
    passwordNumericRule,
    passwordSymbolRule,
    passwordUppercaseRule,
    PASSWWORD_SUGGESTIONS_DICTIONARY,
    REQUIRED_PASSWORD_VALIDATION_RULE,
    VALIDATION_MESSAGES
} from "../../constants";
import {usePasswordSuggestions} from "../../customHooks/usePasswordSuggesions";
import {SecondaryButton} from "../common/Buttons";
import {useRootStore} from "../../store/RootStore";

const {LOCAL_LOGIN_ENABLED} = ENV_CONST;

export const ProfileAccountForm = observer(() => {
    const {userStore} = useRootStore();
    const {translate} = useSafeTranslations();

    const canShowResetPassword = LOCAL_LOGIN_ENABLED && userStore.provider === "custom";
    const userEmail = userStore.userEmail;

    const defaultValues: IProfilePasswordData = {
        currentPassword: "",
        newPassword: "",
        confirmPassword: ""
    };

    const {
        control,
        handleSubmit,
        setError,
        reset,
        watch,
        getValues,
        formState: {errors, isSubmitting}
    } = useForm<IProfilePasswordData>({defaultValues});

    const currentPasswordValue = watch("currentPassword") || "";
    const newPasswordValue = watch("newPassword") || "";
    const confirmPasswordValue = watch("confirmPassword") || "";

    const currentPasswordRulles = usePasswordSuggestions(
        currentPasswordValue,
        PASSWWORD_SUGGESTIONS_DICTIONARY
    );

    const newPasswordRulles = usePasswordSuggestions(
        newPasswordValue,
        PASSWWORD_SUGGESTIONS_DICTIONARY
    );

    const confirmPasswordRulles = usePasswordSuggestions(
        confirmPasswordValue,
        PASSWWORD_SUGGESTIONS_DICTIONARY
    );

    const apiCall: ApiCall<IProfilePasswordData, INewPasswordResponse> = data =>
        API.changePass(data);

    const {onSubmit, isSuccessfullySubmitted} = useFormSubmit<
        IProfilePasswordData,
        INewPasswordResponse
    >({
        apiCall,
        fields: ["currentPassword", "newPassword", "confirmPassword"],
        setError,
        onSuccess: data => {
            console.log("onSuccess", data);
            reset();
        }
    });

    //

    return (
        <form
            className="form form--wide form--tab-adapted form--profile-account"
            onSubmit={handleSubmit(onSubmit)}
        >
            <FormHeader></FormHeader>
            <FormContent>
                <div className="grid">
                    <div className="col-6 p-5 pb-0 pt-0">
                        <Header4>{"Account information"}</Header4>

                        <TextField
                            id={"email"}
                            type={"email"}
                            value={userEmail || ""}
                            disabled={true}
                        />

                        {!canShowResetPassword ? (
                            <>
                                <ContentB1>{`Logged in via ${userStore.provider} `}</ContentB1>
                            </>
                        ) : null}

                        {canShowResetPassword && (
                            <>
                                <Header4>{"Reset password"}</Header4>
                                <PasswordFieldControlled
                                    name={"currentPassword"}
                                    placeholder={translate("current-password-text")}
                                    disabled={isSubmitting}
                                    control={control}
                                    rulles={REQUIRED_PASSWORD_VALIDATION_RULE}
                                    errors={errors.currentPassword}
                                    footer={
                                        <PasswordSuggestionsFooter rules={currentPasswordRulles} />
                                    }
                                />
                                <PasswordFieldControlled
                                    name={"newPassword"}
                                    placeholder={translate("password-text")}
                                    disabled={isSubmitting}
                                    control={control}
                                    rulles={REQUIRED_PASSWORD_VALIDATION_RULE}
                                    errors={errors.newPassword}
                                    footer={<PasswordSuggestionsFooter rules={newPasswordRulles} />}
                                />
                                <PasswordFieldControlled
                                    name={"confirmPassword"}
                                    placeholder={translate("confirm-password-text")}
                                    disabled={isSubmitting}
                                    control={control}
                                    rulles={{
                                        validate: {
                                            ...passwordLengthRule,
                                            ...passwordUppercaseRule,
                                            ...passwordNumericRule,
                                            ...passwordSymbolRule,
                                            isTheSamePassword: (value: string) => {
                                                const newPassValue = getValues("newPassword");
                                                const confirmValue = value;
                                                return (
                                                    (!!newPassValue &&
                                                        confirmValue === newPassValue) ||
                                                    VALIDATION_MESSAGES.PASSWORDS_MUST_MATCH
                                                );
                                            }
                                        }
                                    }}
                                    errors={errors.confirmPassword}
                                    footer={
                                        <PasswordSuggestionsFooter rules={confirmPasswordRulles} />
                                    }
                                />
                                <Field>
                                    <SecondaryButton
                                        type={"submit"}
                                        className={"secondary-button--w-full"}
                                        // onClick={}
                                        label={translate("change-password-text")}
                                        disabled={isSubmitting}
                                    />
                                </Field>
                            </>
                        )}
                    </div>
                    <div className="col-6 p-5 pb-0 pt-0"></div>
                </div>
            </FormContent>
            {canShowResetPassword && (
                <>
                    <AccountFormFooter
                        disabled={isSubmitting}
                        onCancelClick={() => {
                            console.log("cancel");
                        }}
                        onSaveClick={() => {
                            console.log("save");
                        }}
                    />
                </>
            )}
        </form>
    );
});
