import {APIError, CommonError} from "../services/interfaces";
import {ToastContextType} from "./useToast";

type ErrorToastOptions = {
    duration?: number;
};

const useToastError = (
    showToast: ToastContextType,
    errors: (CommonError | APIError)[],
    options: ErrorToastOptions = {duration: 3000}
) => {
    const {duration} = options;

    if (errors && showToast) {
        errors.forEach(error => {
            if (typeof error === "string") {
                showToast("error", "Error", error, "top-center", duration);
            } else {
                showToast("error", "Error", error.message, "top-center", duration);
            }
        });
    }
};

export default useToastError;
