import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {useRootStore} from "../../store/RootStore";
import {useEffect} from "react";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.Favorites;

const FavoritesPage = observer(() => {
    const {userStore, routeStore} = useRootStore();

    return (
        <>
            {userStore.isLoggedIn ? (
                <PageContent pageId={pageId}>
                    <h1>Favorites page</h1>
                </PageContent>
            ) : null}
        </>
    );
});

export default FavoritesPage;
