import {ENV_CONST} from "../../constants";
import {handleErrorException} from "../../helpers";
import {IFlightResponse} from "../interfaces";

const endpoint = "flights";
const {API_URL} = ENV_CONST;

export async function getFlight(flightId: string): Promise<IFlightResponse> {
    const endpointUrl = `${API_URL}${endpoint}`;

    const unknownError: IFlightResponse = {
        data: null,
        errors: [{message: "Unknown get flight server error"}]
    };

    let result: IFlightResponse = unknownError;

    try {
        const response = await fetch(`${endpointUrl}/${flightId}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });

        const data: IFlightResponse = await response.json();
        result = data;
    } catch (error) {
        const message = handleErrorException(error);
        result.errors?.push({message});
        console.error(result.errors);
    }

    return result;
}
