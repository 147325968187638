import {useEffect, useRef} from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {SEARCH_PAGE_PATH} from "../constants";
import {useRootStore} from "../store/RootStore";

export const useSearchParamsSync = (enabled = true) => {
    const {flightSearchStore} = useRootStore();
    const [searchParams, setSearchParams] = useSearchParams();
    const location = useLocation();

    // Флаг для отслеживания инициализации
    const isInitialMount = useRef(true);

    useEffect(() => {
        if (!enabled || location.pathname !== SEARCH_PAGE_PATH) return;

        // Пропускаем первый рендер
        if (isInitialMount.current) {
            isInitialMount.current = false;
            flightSearchStore.parseUrlParams(searchParams).then(() => {
                if (Object.keys(flightSearchStore.searchData).length > 0) {
                    flightSearchStore.search();
                }
            });
            return;
        }

        // Последующие обновления только синхронизируют URL
        const params = flightSearchStore.getUrlParams();
        setSearchParams(params, {replace: true});
    }, [enabled, flightSearchStore.searchData, location.pathname]);

    return {searchParams};
};
