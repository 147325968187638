import {observer} from "mobx-react";
import {Badge} from "primereact/badge";
import {TabPanel, TabView} from "primereact/tabview";
import {useState} from "react";
import {ProfileAccountForm, ProfilePersonalInfoForm} from "../forms";

type TabHeaderProps = {
    text: string;
    showBadge?: boolean;
    badgeValue?: number;
};

const TabHeader = ({text, showBadge = true, badgeValue}: TabHeaderProps) => {
    return (
        <span>
            {text} {showBadge && <Badge className="ml-2" value={badgeValue} severity="danger" />}
        </span>
    );
};

export const AccountTabs = observer(() => {
    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <TabView
            activeIndex={activeIndex}
            onTabChange={e => setActiveIndex(e.index)}
            className={"tabs tabs--header-centered tabs--account"}
        >
            <TabPanel
                header={<TabHeader text={"Account"} showBadge={true} badgeValue={undefined} />}
            >
                <ProfileAccountForm />
            </TabPanel>

            <TabPanel
                header={
                    <TabHeader text={"Personal Info"} showBadge={true} badgeValue={undefined} />
                }
            >
                <ProfilePersonalInfoForm />
            </TabPanel>
        </TabView>
    );
});
