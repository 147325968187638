import {useNavigate} from "react-router-dom";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {Button} from "primereact/button";
import {classNames} from "primereact/utils";

type LoginButtonProps = {
    className?: string;
};

const LoginButton = ({className}: LoginButtonProps) => {
    const {translate} = useSafeTranslations();
    const navigate = useNavigate();

    const onLoginClick = () => {
        //todo from const
        navigate("/login");
    };

    return (
        <div className={classNames(className, "justify-content-center align-items-center")}>
            <Button
                onClick={onLoginClick}
                label={translate("main-login-btn-text")}
                className={classNames("main-button login-button")}
            />
        </div>
    );
};

export default LoginButton;
