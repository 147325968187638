import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.MyFlights;

function MyFlights() {
    return (
        <PageContent pageId={pageId}>
            <h1>My Flights</h1>
        </PageContent>
    );
}

export default MyFlights;
