import {action, makeObservable, observable} from "mobx";
import {RootStore} from "../RootStore";
import {Booking} from "../../services/interfaces";

export class BookingStore {
    public lastBooking: Booking | null = null;

    constructor(protected rootStore: RootStore) {
        makeObservable(this, {
            lastBooking: observable,
            setLastBooking: action
        });
    }

    setLastBooking(booking: Booking | null) {
        this.lastBooking = booking;
    }
}
