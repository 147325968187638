import React, {createContext, useContext, useRef, ReactNode, useState} from "react";
import {Toast} from "primereact/toast";

// Определяем типы для параметров, которые принимает функция showToast
interface ToastParams {
    severity: "success" | "info" | "warn" | "error";
    summary: string;
    detail: string;
    life?: number;
    position?:
        | "top-right"
        | "top-left"
        | "top-center"
        | "bottom-right"
        | "bottom-left"
        | "bottom-center";
}

// Типизация контекста
export type ToastContextType =
    | ((
          severity: ToastParams["severity"],
          summary: string,
          detail: string,
          position?: ToastParams["position"],
          life?: number
      ) => void)
    | null;

// Создаем контекст для тоста с типизацией
const ToastContext = createContext<ToastContextType>(null);

// Интерфейс для пропсов ToastProvider
interface ToastProviderProps {
    children: ReactNode;
}

// Провайдер для тоста с типизацией
export const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
    const toastRef = useRef<Toast>(null);
    const [toastPosition, setToastPosition] = useState<ToastParams["position"]>("top-right");

    const showToast = (
        severity: ToastParams["severity"],
        summary: string,
        detail: string,
        position: ToastParams["position"] = "top-right",
        life = 3000
    ) => {
        setToastPosition(position);
        toastRef.current?.show({
            severity,
            summary,
            detail,
            life
        });
    };

    return (
        <ToastContext.Provider value={showToast}>
            <Toast ref={toastRef} position={toastPosition} />
            {children}
        </ToastContext.Provider>
    );
};

// Хук для доступа к контексту тоста
export const useToast = (): ToastContextType => {
    return useContext(ToastContext);
};
