export enum PagesId {
    Home = "Home",
    Login = "Login",
    Reset = "Reset",
    ChangePassword = "ChangePassword",
    Search = "Search",
    Fligths = "Flight",
    Buy = "Buy",
    Account = "Account",
    MyFlights = "My Flights",
    Favorites = "Favorites",
    About = "About",
    Support = "Support",
    Error = "Error"
}
