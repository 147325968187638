import {Avatar} from "primereact/avatar";
import {Menu} from "primereact/menu";
import {MenuItem} from "primereact/menuitem";
import React, {useRef} from "react";
import {useNavigate} from "react-router-dom";
import {PageslInfo} from "../../constants";
import {PagesId} from "../../constants/PAGES_ID";

interface UserAvatarProps {
    avatarUrl?: string;
    username: string;
    onLogout: () => Promise<void> | void;
    className?: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({avatarUrl, username, onLogout}) => {
    const menu = useRef<Menu>(null);
    const navigate = useNavigate();

    const loginPath = PageslInfo.find(p => p.id === PagesId.Login)?.path;
    const accountPath = PageslInfo.find(p => p.id === PagesId.Account)?.path;
    const loginLink = loginPath ? `/${loginPath}` : "/login";
    const accountLink = accountPath ? `/${accountPath}` : "/account";

    const items: MenuItem[] = [
        {
            label: "Account",
            icon: "pi pi-user",
            command: () => {
                navigate(accountLink);
            }
        },
        {
            separator: true
        },
        {
            label: "Logout",
            icon: "pi pi-power-off",
            command: async () => {
                try {
                    await handleLogout();
                } catch (error) {
                    console.error("Error logging out", error);
                }
            }
        }
    ];

    const handleLogout = async (): Promise<void> => {
        await onLogout();
        navigate(loginLink);
    };

    return (
        <div className="user-avatar-container">
            <Avatar
                image={avatarUrl}
                label={username.charAt(0).toUpperCase()}
                shape="circle"
                size="large"
                onClick={(e: React.MouseEvent) => menu.current?.toggle(e)}
                className="cursor-pointer"
            />
            <Menu model={items} popup ref={menu} className="user-menu" />
        </div>
    );
};

export default UserAvatar;
