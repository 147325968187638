import {Galleria} from "primereact/galleria";
import {useState, useRef} from "react";
import "./FlightGallery.scss";
interface ImageGalleryProps {
    images: string[];
    className?: string;
}

export const FlightGallery = ({images, className}: ImageGalleryProps) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const itemTemplate = (item: string) => {
        return <img src={item} alt="gallery" />;
    };

    const thumbnailTemplate = (item: string) => {
        return <img src={item} alt="thumbnail" />;
    };

    return (
        <Galleria
            value={images}
            activeIndex={activeIndex}
            onItemChange={e => setActiveIndex(e.index)}
            circular
            item={itemTemplate}
            thumbnail={thumbnailTemplate}
            className={className}
            showItemNavigators
            thumbnailsPosition="bottom"
        />
    );
};
