import {PagesTitles} from "../constants";
import {PagesId} from "../constants/PAGES_ID";
import {Currency, TicketData, TicketsSearchResult} from "../services/interfaces";

export const getPageMetaById = (id: PagesId) => {
    return {
        title: PagesTitles[id]
    };
};

export const handleErrorException = (e: any): string => {
    let errorMessage: string;
    // Check if the error is an instance of Error
    if (e instanceof Error) {
        errorMessage = e.message; // Standard Error message
    } else if (typeof e === "string") {
        errorMessage = e; // If the error is already a string
    } else {
        errorMessage = JSON.stringify(e); // Fallback for non-standard errors
    }
    return errorMessage;
};

export const groupTicketsByDate = (tickets: TicketData[]): TicketsSearchResult[] => {
    const groupedMap = new Map<
        string,
        {
            sortDate: Date;
            tickets: TicketData[];
        }
    >();

    tickets.forEach(ticket => {
        const [datePart] = ticket.date.split(" ");
        const date = new Date(datePart);

        const dateKey = date.toLocaleString("en-US", {
            day: "numeric",
            month: "long"
        });

        if (!groupedMap.has(dateKey)) {
            groupedMap.set(dateKey, {
                sortDate: date,
                tickets: []
            });
        }

        groupedMap.get(dateKey)?.tickets.push(ticket);
    });

    return Array.from(groupedMap.entries())
        .sort((a, b) => a[1].sortDate.getTime() - b[1].sortDate.getTime())
        .map(([dateKey, {tickets}]) => ({
            sortData: {
                type: "date",
                value: dateKey
            },
            data: tickets.sort((a, b) => {
                const dateA = new Date(a.date);
                const dateB = new Date(b.date);
                return dateA.getTime() - dateB.getTime();
            })
        }));
};

export const formatPrice = (price: number, currency: Currency = "USD"): string => {
    switch (currency) {
        case "USD":
            return `$${price} USD`;
        case "EUR":
            return `€${price} EUR`;
        default:
            return `${price} ${currency}`;
    }
};
