import {ENV_CONST} from "../../constants";
import {handleErrorException} from "../../helpers";
import {ILoginData, ILoginResponse} from "../interfaces";

const {API_URL} = ENV_CONST;
const endpoint = "login_or_register";

export async function login({
    provider,
    idToken,
    email,
    loginPassword,
    staySignedIn
}: ILoginData): Promise<ILoginResponse> {
    if (provider) {
        let result: ILoginResponse = {
            data: {session_token: ""},
            errors: ["Unknown login error"]
        };

        const endpointUrl = `${API_URL}${endpoint}/${provider}`;

        try {
            const response = await fetch(endpointUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    idToken,
                    email
                })
            });

            const data: ILoginResponse = await response.json();
            result = data;

            console.log(data);
        } catch (e) {
            const message = handleErrorException(e);
            result.errors?.push({message});
            console.error(result.errors);
        }

        return result;
    }

    const res: ILoginResponse = {
        data: {session_token: ""},
        errors: ["Unknown auth provider"]
    };

    return res;
}
