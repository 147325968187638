type DateFormat = "dd-MM-yyyy"; // пока один формат, потом можно расширить через |
type EuroDateString = string;

class InvalidDateError extends Error {
    constructor(message: string) {
        super(message);
        this.name = "InvalidDateError";
    }
}

// Парсинг строки в Date
export function parseEuroDate(dateStr: EuroDateString): Date {
    const isValidFormat = /^\d{2}-\d{2}-\d{4}$/.test(dateStr);
    if (!isValidFormat) {
        throw new InvalidDateError("Date string must be in format dd-MM-yyyy");
    }

    const [dayStr, monthStr, yearStr] = dateStr.split("-");
    const day = parseInt(dayStr, 10);
    const month = parseInt(monthStr, 10) - 1;
    const year = parseInt(yearStr, 10);

    if (month < 0 || month > 11) {
        throw new InvalidDateError("Month must be between 1 and 12");
    }
    if (day < 1 || day > 31) {
        throw new InvalidDateError("Day must be between 1 and 31");
    }
    if (year < 1900 || year > 9999) {
        throw new InvalidDateError("Year must be between 1900 and 9999");
    }

    const date = new Date(year, month, day);

    if (date.getMonth() !== month) {
        throw new InvalidDateError("Invalid date for given month");
    }

    return date;
}

export const formatDate = (date: Date | null, format: DateFormat = "dd-MM-yyyy"): string | null => {
    if (!date) return null;

    switch (format) {
        case "dd-MM-yyyy": {
            const day = String(date.getDate()).padStart(2, "0");
            const month = String(date.getMonth() + 1).padStart(2, "0");
            const year = date.getFullYear();
            return `${day}-${month}-${year}`;
        }
        default: {
            throw new Error(`Unsupported date format: ${format}`);
        }
    }
};

export const addDays = (date: Date, days: number): Date => {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
};

export function formatDuration(minutes: number): string {
    if (minutes === 0) return "0m";

    const days = Math.floor(minutes / (24 * 60));
    const hours = Math.floor((minutes % (24 * 60)) / 60);
    const mins = minutes % 60;

    const parts: string[] = [];

    if (days > 0) parts.push(`${days}d`);
    if (hours > 0) parts.push(`${hours}h`);
    if (mins > 0) parts.push(`${mins}m`);

    return parts.join(" ");
}

export function convertTimeToAmPmFormat(dateTime: string): string {
    const date = new Date(dateTime);
    return date.toLocaleTimeString("en-US", {
        hour: "numeric",
        minute: "2-digit",
        hour12: true
    });
}
