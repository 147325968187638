import {Random} from "../../utils/Random";
import {WaitAction} from "../../utils/WaitAction";

// TODO : change when API will be ready
export async function validateResetToken(token: string): Promise<boolean> {
    if (!token) return false;

    const delay = Random.rangeFloat(0.5, 1.5);
    const reult = Random.bool();
    await WaitAction.sec(delay);
    return reult;
}
