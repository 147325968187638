import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {AccountTabs} from "../../components/tabs/AccountTabs";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.Account;

const AccountPage = observer(() => {
    return (
        <PageContent pageId={pageId}>
            <AccountTabs />
        </PageContent>
    );
});

export default AccountPage;
