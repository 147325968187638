import "./StickyFooter.scss";

interface StickyFooterProps {
    children: React.ReactNode;
    className?: string;
}

export const StickyFooter: React.FC<StickyFooterProps> = ({
    children,
    className = ""
}: StickyFooterProps) => {
    return (
        <div
            className={`sticky-footer fixed bottom-0 left-0 right-0 bg-white p-3 z-5 ${className}`}
        >
            {children}
        </div>
    );
};
