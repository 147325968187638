import {ENV_CONST} from "../../constants";
import {handleErrorException} from "../../helpers";
import {WaitAction} from "../../utils/WaitAction";
import {IAirportsResponse} from "../interfaces";

const endpoint = "airports";
const {API_URL} = ENV_CONST;

const unknownError: IAirportsResponse = {
    data: null,
    errors: [{message: "Unknown get airports server error"}]
};

export async function getAirports(): Promise<IAirportsResponse> {
    let result: IAirportsResponse = unknownError;
    const endpointUrl = `${API_URL}${endpoint}`;

    try {
        const response = await fetch(endpointUrl, {
            method: "GET",
            headers: {
                "Content-Type": "application/json"
            }
        });
        const data: IAirportsResponse = await response.json();
        result = data;
        console.log(data);
    } catch (error) {
        const message = handleErrorException(error);
        result.errors?.push({message});
        console.error(result.errors);
    }
    return result;
}
