import React, {ReactNode} from "react";

interface MainProps {
    children: ReactNode;
}

const Main: React.FC<MainProps> = ({children}) => {
    return <main className="w-full flex-grow /*p-3*/ bg-gray-100">{children}</main>;
};

export default Main;
