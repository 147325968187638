import {ProgressSpinner} from "primereact/progressspinner";
import "./loading-components.scss";

export const PageAuthPreloader = () => {
    return (
        <div className="page-auth-preloader">
            <ProgressSpinner style={{width: "150px", height: "150px"}} />
        </div>
    );
};
