/* eslint-disable max-len */
import {ClientValidationRule, ClientValidationService} from "../services/ClientValidationService";
import rootStore from "../store/RootStore";
import {VALIDATION_CONFIG} from "./VALIDATION_CONFIG";
import {VALIDATION_MESSAGES} from "./VALIDATION_MESSAGES";

type HookFormClientValidationRuleChecker<T = string> = {
    [key in ClientValidationRule]?: (value: T) => string | boolean;
};

type SuggestionsMessageItem = {
    key: ClientValidationRule;
    message: string;
    validator: HookFormClientValidationRuleChecker;
};

export type SuggestionsDictionary = SuggestionsMessageItem[];

// Required
const firstNameRequired = {required: VALIDATION_MESSAGES.FIRS_NAME_REQUIRED};
const lastNameRequired = {required: VALIDATION_MESSAGES.LAST_NAME_REQUIRED};
const emailRequired = {required: VALIDATION_MESSAGES.EMAIL_REQUIRED};
const passwordRequired = {required: VALIDATION_MESSAGES.PASSWORD_REQUIRED};
const idImageRequired = {required: VALIDATION_MESSAGES.ID_IMAGE_REQUIRED};
const idNumberRequired = {required: VALIDATION_MESSAGES.ID_NUMBER_REQUIRED};
const passengersRequired = {required: VALIDATION_MESSAGES.MIN_PASSENGERS};
const airtportFromRequired = {required: "Airport is required"};
const airtportToRequired = {required: "Airport is required"};

// Rules
const validateEmailRule: HookFormClientValidationRuleChecker = {
    isValidEmail: (value: string) => {
        return ClientValidationService.isValidEmail(value) || VALIDATION_MESSAGES.EMAIL_INVALID;
    }
};

export const passwordLengthRule: HookFormClientValidationRuleChecker = {
    isValidMinLength: (value: string) => {
        return (
            ClientValidationService.isValidMinLength(value, VALIDATION_CONFIG.MIN_PASS_LENGTH) ||
            VALIDATION_MESSAGES.MIN_PASS_LENGHT
        );
    }
};

export const passwordUppercaseRule: HookFormClientValidationRuleChecker = {
    hasUppercase: (value: string) => {
        return ClientValidationService.hasUppercase(value) || VALIDATION_MESSAGES.HAS_UPPERCASE;
    }
};

export const passwordNumericRule: HookFormClientValidationRuleChecker = {
    hasNumeric: (value: string) => {
        return ClientValidationService.hasNumeric(value) || VALIDATION_MESSAGES.HAS_NUMERIC;
    }
};

export const passwordSymbolRule: HookFormClientValidationRuleChecker = {
    containsSymbol: (value: string) => {
        return (
            ClientValidationService.containsSymbol(
                value,
                VALIDATION_CONFIG.PASS_REQUIERED_SYMBOLS
            ) || VALIDATION_MESSAGES.CONTAIN_SYMBOL
        );
    }
};

const idNumberMinLengthRule: HookFormClientValidationRuleChecker = {
    isValidMinLength: (value: string) => {
        return (
            ClientValidationService.isValidMinLength(
                value,
                VALIDATION_CONFIG.MIN_ID_NUMBER_LENGTH
            ) || VALIDATION_MESSAGES.MIN_PASS_LENGHT
        );
    }
};

const passengersTotalRule: HookFormClientValidationRuleChecker<number> = {
    isNotGreaterThan: (value: number) => {
        return (
            ClientValidationService.isNotGreaterThan(
                value,
                VALIDATION_CONFIG.MAX_PASSENGERS_TOTAL
            ) || `Max passengers: ${VALIDATION_CONFIG.MAX_PASSENGERS_TOTAL}.`
        );
    },
    isNotLessThan: (value: number) => {
        return (
            ClientValidationService.isNotLessThan(value, 1) || VALIDATION_MESSAGES.MIN_PASSENGERS
        );
    }
};

const idNumberMaxLengthRule: HookFormClientValidationRuleChecker = {
    isValidMaxLength: (value: string) => {
        return (
            ClientValidationService.isValidMaxLength(
                value,
                VALIDATION_CONFIG.MAX_ID_NUMBER_LENGTH
            ) || VALIDATION_MESSAGES.MAX_ID_NUMBER_LENGHT
        );
    }
};

const idNumberAlphanumericRule: HookFormClientValidationRuleChecker = {
    isAlphaNumeric: (value: string) => {
        return ClientValidationService.isAlphaNumeric(value) || VALIDATION_MESSAGES.ALPHANUMERIC;
    }
};

const idImageImageFileTypeRule: HookFormClientValidationRuleChecker<File[]> = {
    isValidFileType: (value: File[]) => {
        return (
            ClientValidationService.isValidFileType(value, VALIDATION_CONFIG.IMAGE_FILE_TYPES) ||
            VALIDATION_MESSAGES.ID_IMAGE_TYPES
        );
    }
};

const idImageFileSizeRule: HookFormClientValidationRuleChecker<File[]> = {
    isValidFileSize: (value: File[]) => {
        return (
            ClientValidationService.isValidFileSize(
                value,
                VALIDATION_CONFIG.UPLOAD_IMAGE_FILE_SIZE_LIMIT
            ) || VALIDATION_MESSAGES.ID_IMAGE_SIZE
        );
    }
};

// Rules compose
export const EMAIL_VALIDATION_RULES = {...emailRequired, validate: {...validateEmailRule}};
export const FIRST_NAME_VALIDATION_RULE = {...firstNameRequired};
export const LAST_NAME_VALIDATION_RULE = {...lastNameRequired};
export const PASSENGERS_TOTAL_VALIDATION_RULE = {
    ...passengersRequired,
    validate: {...passengersTotalRule}
};
//
export const AIRPORT_FROM_VALIDATION_RULE = {
    validate: {
        isValidAirport: (value: any) => {
            if (value && typeof value === "object") {
                if (
                    "iata" in value &&
                    "name" in value &&
                    "city" in value &&
                    "country_code" in value
                ) {
                    return true;
                }
            }

            return "Airport not found";
        }
    }
};
// export const AIRPORT_FROM_VALIDATION_RULE = {...airtportFromRequired};
// export const AIRPORT_TO_VALIDATION_RULE = {...airtportToRequired};
export const AIRPORT_TO_VALIDATION_RULE = {
    validate: {
        isValidAirport: (value: any) => {
            if (value && typeof value === "object") {
                if (
                    "iata" in value &&
                    "name" in value &&
                    "city" in value &&
                    "country_code" in value
                ) {
                    return true;
                }
            }

            return "Airport not found";
        }
    }
};
//
export const UPLOAD_ID_IMAGE_VALIDATION_RULE = {
    ...idImageRequired,
    validate: {...idImageImageFileTypeRule, ...idImageFileSizeRule}
};
export const ID_NUMBER_VALIDATION_RULE = {
    ...idNumberRequired,
    validate: {...idNumberMinLengthRule, ...idNumberMaxLengthRule, ...idNumberAlphanumericRule}
};

export const PHONE_OPTIONAL_VALIDATION_RULE = {
    validate: {
        isValidPhone: (value: string) => {
            if (!value) return true;
            return (
                ClientValidationService.isValidPhone(value) ||
                VALIDATION_MESSAGES.PHONE_NUMBER_INVALID
            );
        }
    }
};

export const BIRTHDAY_OPTIONAL_VALIDATION_RULE = {
    validate: {
        isValidDate: (value: string) => {
            if (!value) return true;
            return ClientValidationService.isValidDate(value) || VALIDATION_MESSAGES.DATE_INVALID;
        },
        isNotFutureDate: (value: Date | string) => {
            if (!value) return true;
            return (
                ClientValidationService.isNotFutureDate(value) ||
                VALIDATION_MESSAGES.BIRTHDAY_IN_FUTURE
            );
        }
    }
};

export const REQUIRED_PASSWORD_VALIDATION_RULE = {
    ...passwordRequired,
    validate: {
        ...passwordLengthRule,
        ...passwordUppercaseRule,
        ...passwordNumericRule,
        ...passwordSymbolRule
    }
};

export const PASSWORD_VALIDATION_RULE = {
    validate: {
        ...passwordLengthRule,
        ...passwordUppercaseRule,
        ...passwordNumericRule,
        ...passwordSymbolRule
    }
};

export const PASSWWORD_SUGGESTIONS_DICTIONARY: SuggestionsDictionary = [
    {
        key: "isValidMinLength",
        message: VALIDATION_MESSAGES.MIN_PASS_LENGHT,
        validator: passwordLengthRule
    },
    {
        key: "hasUppercase",
        message: VALIDATION_MESSAGES.HAS_UPPERCASE,
        validator: passwordUppercaseRule
    },
    {
        key: "hasNumeric",
        message: VALIDATION_MESSAGES.HAS_NUMERIC,
        validator: passwordNumericRule
    },
    {
        key: "containsSymbol",
        message: VALIDATION_MESSAGES.CONTAIN_SYMBOL,
        validator: passwordSymbolRule
    }
];
