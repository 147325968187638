import i18n from "../lang";
import {VALIDATION_CONFIG} from "./VALIDATION_CONFIG";

const {MIN_PASS_LENGTH, MIN_ID_NUMBER_LENGTH, MAX_ID_NUMBER_LENGTH, PASS_REQUIERED_SYMBOLS} =
    VALIDATION_CONFIG;

export const VALIDATION_MESSAGES = {
    FIRS_NAME_REQUIRED: "First Name is required",
    LAST_NAME_REQUIRED: "Last Name is required",
    ID_IMAGE_REQUIRED: "ID Image is required",
    ID_IMAGE_TYPES: "ID Image must be of type PNG, JPEG, JPG",
    ID_IMAGE_SIZE: "The file is too large. Maximum size is 2MB.", // TODO use variable
    PHONE_NUMBER_INVALID: "Phone number is not valid",
    DATE_INVALID: "Date is not valid",
    BIRTHDAY_IN_FUTURE: "Birthday cannot be in the future",
    EMAIL_REQUIRED: i18n.t("validation-message-email-required"),
    PASSWORD_REQUIRED: "Password is required",
    EMAIL_INVALID: "Invalid email",
    MIN_PASS_LENGHT: i18n.t("validation-message-min-pass-length", {MIN_PASS_LENGTH}),
    MIN_ID_NUMBER_LENGHT: i18n.t("validation-message-min-id-number-length", {MIN_ID_NUMBER_LENGTH}),
    MAX_ID_NUMBER_LENGHT: i18n.t("validation-message-max-id-numer-length", {MAX_ID_NUMBER_LENGTH}),
    ALPHANUMERIC: i18n.t("validation-message-alphanumeric"),
    HAS_NUMERIC: "Must contain at least one number",
    HAS_UPPERCASE: "Must contain at least one uppercase letter",
    CONTAIN_SYMBOL: `Must contain at least one of ${PASS_REQUIERED_SYMBOLS}`,
    PASSWORDS_MUST_MATCH: "Passwords do not match",
    ID_NUMBER_REQUIRED: "ID number is required",
    MIN_PASSENGERS: "Enter at least one passenger"
};
