import React, {useState} from "react";
import "./FlightCardThumbnail.scss";

interface ThumbnailProps {
    src: string;
    alt?: string;
}

export const FlightCardThumbnail: React.FC<ThumbnailProps> = ({src, alt = ""}) => {
    const [isLoaded, setIsLoaded] = useState(false);

    return (
        <div className="thumbnail-container">
            <img
                className={`thumbnail ${isLoaded ? "loaded" : ""}`}
                src={src}
                alt={alt}
                onLoad={() => setIsLoaded(true)}
                loading="lazy"
            />
        </div>
    );
};
