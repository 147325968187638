import {observer} from "mobx-react";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {useRootStore} from "../../store/RootStore";
import {useEffect} from "react";
import {FlightSearchForm} from "../../components/forms/FlightSearchForm";
import {PageAuthPreloader} from "../../components/LoadingComponents/PageAuthPreloader";
import {EmptyFlightsResult} from "./EmptySearchResult";
import {FlightCardCollection} from "../../components/cards/FlightCardCollection";
import {useToast} from "../../customHooks/useToast";
import useToastError from "../../customHooks/useToastError";

const pageId = PagesId.Search;

const SearchPage = observer(() => {
    const {userStore, flightSearchStore, referenceStore} = useRootStore();
    const showToast = useToast();

    // Todo: посмотоеть куда это можно вынести
    const isLoading = flightSearchStore.isLoading || referenceStore.airportsLoading;

    useEffect(() => {
        if (flightSearchStore.isError) {
            useToastError(showToast, flightSearchStore.errors);
        }
    }, [flightSearchStore.isError]);

    useEffect(() => {
        return () => {
            flightSearchStore.resetSeachForm();
            flightSearchStore.resetSearchState();
        };
    }, []);

    return (
        <PageContent pageId={pageId}>
            <FlightSearchForm syncWithUrl={true} />

            {isLoading ? (
                <PageAuthPreloader />
            ) : flightSearchStore.ticketsSearchResult.length > 0 ? (
                <FlightCardCollection ticketsSearchResult={flightSearchStore.ticketsSearchResult} />
            ) : (
                <EmptyFlightsResult />
            )}
        </PageContent>
    );
});

export default SearchPage;
