import {observer} from "mobx-react";
import {useEffect} from "react";
import {FlightSearchForm} from "../../components/forms/FlightSearchForm";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";
import {useRootStore} from "../../store/RootStore";
import {HomeWelcomeBlock} from "./WelcomeBlock";

const pageId = PagesId.Home;

const HomePage = observer(() => {
    const {flightSearchStore} = useRootStore();

    useEffect(() => {
        flightSearchStore.resetAll();
    }, []);

    return (
        <PageContent className={"page-home"} pageId={pageId}>
            <HomeWelcomeBlock />
            <FlightSearchForm syncWithUrl={false} />
        </PageContent>
    );
});

// Default export
export default HomePage;
