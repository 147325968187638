import PageContent from "../../components/MainPageBlocks/PageContent";
import {PagesId} from "../../constants/PAGES_ID";

const pageId = PagesId.About;

function AboutPage() {
    return (
        <PageContent pageId={pageId}>
            <h1>About page</h1>
        </PageContent>
    );
}

export default AboutPage;
