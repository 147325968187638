import {observer} from "mobx-react";
import {useEffect} from "react";
import {useParams} from "react-router-dom";
import {TicketCardFlightRouteDetails} from "../../components/cards/CardComponents";
import {Header2, Header5} from "../../components/common";
import {FlightGallery} from "../../components/galleries/FlightGallery";
import {PageAuthPreloader} from "../../components/LoadingComponents/PageAuthPreloader";
import PageContent from "../../components/MainPageBlocks/PageContent";
import {StickyFooter} from "../../components/StickyFooter/StickyFooter";
import {PagesId} from "../../constants/PAGES_ID";
import {useRootStore} from "../../store/RootStore";
import {EmptyFlightsResult} from "../Search/EmptySearchResult";
import {BuyFlight} from "./BuyFlight";
import "./Flights.scss";

const pageId = PagesId.Fligths;

const FlightsPage = observer(() => {
    const {flightSearchStore} = useRootStore();
    const {flight_id} = useParams<{flight_id: string}>();

    useEffect(() => {
        if (flight_id) {
            console.log("flight_id", flight_id);
            flightSearchStore.getCurrentFlight(flight_id);
        }

        return () => {
            flightSearchStore.resetCurrentFlight();
        };
    }, [flight_id]);

    const {currentFlight} = flightSearchStore;

    return (
        <>
            <PageContent pageId={pageId}>
                {currentFlight.isLoading ? (
                    <PageAuthPreloader />
                ) : currentFlight.data ? (
                    <>
                        <div className="flight">
                            <div className="flight-photos">
                                <FlightGallery
                                    className="flight-photos-gallery"
                                    images={[
                                        ...currentFlight.data.aircraftData.exteriorPhotos,
                                        ...currentFlight.data.aircraftData.interiorPhotos
                                    ]}
                                />
                            </div>
                            <div className="flight-details">
                                <Header2>{currentFlight.data.aircraftData.name}</Header2>
                                {/* Create component */}
                                <div className="flight-details__description">
                                    {currentFlight.data.aircraftData.description}
                                </div>
                                <Header5>{"Flight details"}</Header5>
                                <TicketCardFlightRouteDetails
                                    flightRouteData={currentFlight.data.flightRouteData}
                                />
                            </div>
                        </div>
                        <StickyFooter>
                            <BuyFlight
                                flightId={currentFlight.data.id}
                                price={currentFlight.data.price}
                                currency={currentFlight.data.currency}
                                maxSeats={currentFlight.data.seats}
                            />
                        </StickyFooter>
                    </>
                ) : (
                    // <pre>{JSON.stringify(currentFlight.data, null, 2)}</pre>
                    <EmptyFlightsResult type={"flight"} />
                )}
            </PageContent>
        </>
    );
});

export default FlightsPage;
