import React, {ReactNode} from "react";
import {DefaultPageTitle} from "../../constants";
import useDocumentTitle from "../../customHooks/useDocumentTitle";
import {getPageMetaById} from "../../helpers";
import {PagesId} from "../../constants/PAGES_ID";
import {classNames} from "primereact/utils";

interface PageContentProps {
    pageId?: PagesId;
    children?: ReactNode;
    className?: string;
}

const PageContent: React.FC<PageContentProps> = ({children, className, pageId}) => {
    useDocumentTitle(pageId ? getPageMetaById(pageId).title : DefaultPageTitle);
    const baseStyles = "grid grid-nogutter h-full justify-content-center align-items-start p-3"; // todo change primeflex classes to other

    return (
        <div className={classNames(baseStyles, "page", className)}>
            <div className="main-content col-12 sm:col-12 md:col-12 lg:col-12 xl:col-12">
                <div
                    className={classNames(
                        "w-full h-full grid grid-nogutter justify-content-center"
                    )}
                >
                    {children ? children : null}
                </div>
            </div>
        </div>
    );
};

export default PageContent;
