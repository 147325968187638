const GOOGLE_LOGIN_ENABLED = process.env.REACT_APP_GOOGLE_LOGIN_ENABLED === "true";
const APPLE_LOGIN_ENABLED = process.env.REACT_APP_APPLE_LOGIN_ENABLED === "true";
const LOCAL_LOGIN_ENABLED = process.env.REACT_APP_LOCAL_LOGIN_ENABLED === "true";
const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

const IS_DEV_MODE = process.env.REACT_APP_ENV === "development";
const IS_LOCAL_MODE = process.env.REACT_APP_ENV === "local";

export const ENV_CONST = {
    IS_DEV_MODE: IS_DEV_MODE || IS_LOCAL_MODE,
    IS_DEV_FRONTEND_MODE: true,
    API_URL: process.env.REACT_APP_API_URL || "http://localhost:4000/api/v1",
    APPLE_LOGIN_ENABLED,
    LOCAL_LOGIN_ENABLED,
    GOOGLE_LOGIN_ENABLED: GOOGLE_LOGIN_ENABLED && GOOGLE_CLIENT_ID,
    GOOGLE_CLIENT_ID,
    LS_SESSION_KEY: "c8a1d6b0-51f6-4e9e-8b7c-4d3c0c9a1f4e",
    LS_SALT: "f2d3e8a5-93c4-4b0b-94e4-7a2d8f5b6c1f",
    LS_PROVIDER_KEY: "5e4f6b10-9d2a-4c88-8b40-ef7289a8c1d6"
};
