import {ReactNode} from "react";
import {Navigate, useLocation} from "react-router-dom";
import {observer} from "mobx-react";
import {useRootStore} from "../../store/RootStore";
import {PageAuthPreloader} from "../LoadingComponents/PageAuthPreloader";

interface PrivateRouteProps {
    children: ReactNode;
    redirectTo?: string;
}

const PrivateRoute = observer(({children, redirectTo = "/login"}: PrivateRouteProps) => {
    const {userStore, routeStore} = useRootStore();
    const {isLoggedIn, isLoading, isInitializing} = userStore;
    const location = useLocation();

    if ((isInitializing || isLoading) && !isLoggedIn) {
        return <PageAuthPreloader />;
    }

    // Если не авторизован, сохраняем intended route и редиректим
    if (!isLoggedIn) {
        routeStore.setIntendedRoute(location.pathname);
        return <Navigate to={redirectTo} state={{from: location.pathname}} replace />;
    }

    // Если авторизован, очищаем intended route и показываем контент
    if (routeStore.intendedRoute) {
        routeStore.setIntendedRoute(null);
    }

    return <>{children}</>;
});

export default PrivateRoute;
