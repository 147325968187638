import {GoogleLogin} from "@react-oauth/google";
import {observer} from "mobx-react";
import {useRootStore} from "../../store/RootStore";
import {ILoginResponse, IUserResponse} from "../../services/interfaces";
import {API} from "../../services/ApiService";
import {SessionService} from "../../services/SessionService";

export const GoogleLoginComponent = observer(() => {
    const {userStore} = useRootStore();

    return (
        <GoogleLogin
            onSuccess={async credentialResponse => {
                // credentialResponse.credential содержит JWT token
                console.log(credentialResponse.credential);

                userStore.setIsLoading(true);

                try {
                    const provider = "google";
                    userStore.setProvider(provider); // Set the provider

                    const loginResponse: ILoginResponse = await API.login({
                        provider,
                        idToken: credentialResponse.credential
                    });

                    const session_token = loginResponse.data.session_token;
                    userStore.setSessionToken(session_token); // Set the session token

                    const userData: IUserResponse = await API.getUserData(userStore.sessionToken);

                    if (userData.errors.length === 0 && userData.data) {
                        console.log("User:", userData);
                        userStore.setUserDataByResponseData(userData.data);
                        userStore.setLoggedIn(true);
                        SessionService.setToken(userStore.sessionToken);
                        SessionService.setProvider(userStore.provider);
                        //
                    } else {
                        // Hadle error with ui;
                        console.error("Login failed", userData.errors);
                        userStore.logout();
                    }
                } catch (error) {
                    console.error("Login failed", error);
                    userStore.logout();
                    // Handle error with ui
                } finally {
                    userStore.setIsLoading(false);
                    userStore.setIsInitializing(false);
                }

                ///
            }}
            onError={() => {
                console.log("Login Failed");
                userStore.setIsLoading(true);
            }}
        />
    );
});
