export type GoogleUserInfo = {
    sub: string;
    name: string;
    given_name: string;
    family_name: string;
    picture: string;
    email: string;
    email_verified: boolean;
    locale: string;
};

export const googleFetchUserInfo = async (accessToken: string): Promise<GoogleUserInfo> => {
    const res = await fetch("https://www.googleapis.com/oauth2/v3/userinfo", {
        headers: {Authorization: `Bearer ${accessToken}`}
    });
    if (!res.ok) {
        throw new Error("Failed to fetch user info");
    }
    return await res.json();
};
