import {observer} from "mobx-react";
import {ReactNode} from "react";
import {useRootStore} from "../../store/RootStore";
import {Button} from "primereact/button";
import "./header.scss";
import "./header-menubar.scss";
import {useNavigate} from "react-router-dom";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {MainButton} from "../common/Buttons";
import MainMenu from "../MainMenu";
import LoginButton from "./LoginButton";
import UserAvatar from "./UserAvatar";
import {API} from "../../services/ApiService";
import {DefaultHomePageUrl} from "../../constants";
import {classNames} from "primereact/utils";

interface HeaderProps {
    logoComponent: ReactNode;
    menuComponent: ReactNode;
    userComponent?: ReactNode;
}

const Header = observer(({logoComponent, menuComponent, userComponent}: HeaderProps) => {
    const {userStore} = useRootStore();
    const {isLoggedIn, isLoading} = userStore;
    const navigate = useNavigate();

    const userName = userStore.userData.firstName || "U";

    const onLogout = async () => {
        const {provider, accessToken} = userStore;
        const res = await API.logout(provider, accessToken);
        if (res) {
            userStore.logout();
            navigate(DefaultHomePageUrl);
        }
    };

    return (
        <header className="w-full flex justify-content-between align-items-center header">
            <MainMenu
                start={logoComponent}
                end={
                    <>
                        {userComponent ? (
                            userComponent
                        ) : isLoggedIn ? (
                            <UserAvatar username={userName} onLogout={onLogout} />
                        ) : (
                            <LoginButton
                                //Change to separate css class
                                className={classNames({hidden: isLoading, flex: !isLoading})}
                            />
                        )}
                    </>
                }
            />
        </header>
    );
});

export default Header;
