import {ErrorPage} from "../pages";
import AboutPage from "../pages/About";
import AccountPage from "../pages/Account";
import BuyPage from "../pages/Buy";
import ChangePasswordPage from "../pages/ChangePassword";
import FavoritesPage from "../pages/Favorites";
import HomePage from "../pages/Home";
import LoginPage from "../pages/Login";
import MyFlights from "../pages/MyFlights";
import ResetPage from "../pages/Reset";
import SearchPage from "../pages/Search";
import SupportPage from "../pages/Support";
import FlightsPage from "../pages/Flights";
import {PagesId} from "./PAGES_ID";

export const DefaultPageTitle = "JetSaver";
export const DefaultHomePageUrl = "/";

type PageInfo = {
    id: PagesId;
    path: string;
    menuItem: boolean;
    order: number;
    disabled?: boolean;
    isPrivate: boolean;
    menuItemdisabled?: boolean;
    el?: React.ComponentType<any>;
};

export const PagesTitles: {[key in PagesId]: string} = {
    [PagesId.Home]: "Home",
    [PagesId.Login]: "Login",
    [PagesId.Reset]: "Reset password",
    [PagesId.ChangePassword]: "Change password",
    [PagesId.Search]: "Search",
    [PagesId.Fligths]: "Flight",
    [PagesId.Buy]: "Buy",
    [PagesId.Account]: "Account",
    [PagesId.MyFlights]: "My Flights",
    [PagesId.Favorites]: "Favorites",
    [PagesId.About]: "About",
    [PagesId.Support]: "Support",
    [PagesId.Error]: "Error"
};

export const PageslInfo: PageInfo[] = [
    {
        id: PagesId.Home,
        path: "",
        menuItem: true,
        disabled: false,
        isPrivate: false,
        order: 300,
        el: HomePage
    },
    {
        id: PagesId.Login,
        path: "login",
        menuItem: false,
        disabled: false,
        isPrivate: false,
        order: 0,
        el: LoginPage
    },
    {
        id: PagesId.Reset,
        path: "reset-password",
        menuItem: false,
        disabled: true,
        isPrivate: false,
        order: 0,
        el: ResetPage
    },
    {
        id: PagesId.ChangePassword,
        path: "change-password/:token?",
        menuItem: false,
        disabled: true,
        isPrivate: false,
        order: 0,
        el: ChangePasswordPage
    },
    {
        id: PagesId.Search,
        path: "search",
        menuItem: false,
        disabled: false,
        isPrivate: false,
        order: 100,
        el: SearchPage
    },
    {
        id: PagesId.Fligths,
        path: "flights/:flight_id?",
        menuItem: false,
        disabled: false,
        isPrivate: false,
        order: 100,
        el: FlightsPage
    },
    {
        id: PagesId.Buy,
        path: "buy",
        menuItem: false,
        disabled: false,
        isPrivate: false,
        order: 100,
        el: BuyPage
    },
    {
        id: PagesId.Account,
        path: "account",
        menuItem: false,
        disabled: false,
        isPrivate: true,
        order: 100,
        el: AccountPage
    },
    {
        id: PagesId.MyFlights,
        path: "my-flights",
        menuItem: true,
        disabled: false,
        menuItemdisabled: false,
        isPrivate: true,
        order: 200,
        el: MyFlights
    },
    {
        id: PagesId.Favorites,
        path: "favorites",
        menuItem: true,
        disabled: true,
        isPrivate: true,
        order: 250,
        el: FavoritesPage
    },
    {
        id: PagesId.About,
        path: "about",
        menuItem: true,
        disabled: true,
        isPrivate: false,
        order: 150,
        el: AboutPage
    },
    {
        id: PagesId.Support,
        path: "support",
        menuItem: true,
        disabled: true,
        order: 100,
        isPrivate: false,
        el: SupportPage
    },
    {
        id: PagesId.Error,
        path: "error",
        menuItem: false,
        disabled: false,
        isPrivate: false,
        order: 0,
        el: ErrorPage
    }
];

export const RestrictedPages = [PagesId.Login, PagesId.Reset, PagesId.ChangePassword];

const resetPath = PageslInfo.find(p => p.id === PagesId.Reset)?.path;

export const ResetPasswordPageUrl = resetPath ? `/${resetPath}` : DefaultHomePageUrl;
export const SEARCH_PAGE_PATH = "/search";
