export async function googleRevokeToken(token?: string | null): Promise<void> {
    try {
        if (token) {
            await fetch("https://oauth2.googleapis.com/revoke", {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: `token=${token}`
            });
            console.log("Token revoked");
        }
    } catch (error) {
        console.error("Error revoking token:", error);
    } finally {
        console.log("User logged out");
    }
}
