import {createContext, useContext} from "react";
import {configure} from "mobx";
import {UserStore} from "./substores/UserStore";
import {RouteStore} from "./substores/RouteStore";
import {FlightSearchStore} from "./substores/FlightSearchStore";
import {ReferenceStore} from "./substores/ReferenceStore";
import {BookingStore} from "./substores/BookingStore";

configure({enforceActions: "always"});

export class RootStore {
    userStore: UserStore;
    routeStore: RouteStore;
    flightSearchStore: FlightSearchStore;
    referenceStore: ReferenceStore;
    bookingStore: BookingStore;

    constructor() {
        this.userStore = new UserStore(this);
        this.routeStore = new RouteStore(this);
        this.flightSearchStore = new FlightSearchStore(this);
        this.referenceStore = new ReferenceStore(this);
        this.bookingStore = new BookingStore(this);
    }
}

const rootStore = new RootStore();
const RootStoreContext = createContext(rootStore);

export const useRootStore = () => {
    return useContext(RootStoreContext);
};

export default rootStore;
