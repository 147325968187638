import {AirportData, TicketsSearchResult} from "../services/interfaces";
import {addDays, formatDate} from "../utils/Date";

const DAY_OFFSET = 60;
const TODAY = new Date(new Date().setHours(0, 0, 0, 0));
const LAST_DATE = addDays(TODAY, DAY_OFFSET);

export const FLIGHT_SEARCH_CONSTANTS = {
    DEFAULT_PASSENGERS: 1,
    DEFAULT_FROM_IATA: "EDDL",
    DEFAULT_TO_IATA: "LIPK",
    DEFAULT_START_DATE: formatDate(TODAY),
    DEFAULT_END_DATE: formatDate(LAST_DATE)
} as const;

export const DEFAULT_AIRPORT: AirportData = {
    city: "Airport",
    country_code: "Country",
    coords: [0, 0],
    iata: "IATA",
    name: "Name"
};
