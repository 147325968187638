import {AuthProvider} from "../interfaces";
import {googleRevokeToken} from "./googleRevokeToken";

export async function logout(
    provider: AuthProvider,
    accessToken?: string | null
): Promise<boolean> {
    if (provider === "google") {
        googleRevokeToken(accessToken);
    }
    return true;
}
