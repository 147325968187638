import {action, computed, makeObservable, observable, reaction, runInAction, when} from "mobx";
import {createSearchParams} from "react-router-dom";
import {DEFAULT_AIRPORT, FLIGHT_SEARCH_CONSTANTS} from "../../constants";
import {groupTicketsByDate, handleErrorException} from "../../helpers";
import {API} from "../../services/ApiService";
import {
    AircraftData,
    AirportData,
    APIError,
    ChipData,
    CommonError,
    FlightData,
    FlightRouteData,
    IFlightSearchData,
    IFlightSearchParams,
    TicketData,
    TicketsSearchResult
} from "../../services/interfaces";
import {convertTimeToAmPmFormat, formatDuration} from "../../utils/Date";
import {RootStore} from "../RootStore";

export class FlightSearchStore {
    public searchData: IFlightSearchData = {passengers: FLIGHT_SEARCH_CONSTANTS.DEFAULT_PASSENGERS};
    public defaultAirportFrom: AirportData | undefined;
    public defaultAirportTo: AirportData | undefined;
    public searchResult: FlightData[] = [];
    public ticketsSearchResult: TicketsSearchResult[] = [];

    public currentFlight: {
        data?: TicketData;
        isLoading: boolean;
        errors: (CommonError | APIError)[];
    } = {
        isLoading: false,
        errors: []
    };

    public isLoading = false;
    public isError = false;
    public errors: (CommonError | APIError)[] = [];

    constructor(protected rootStore: RootStore) {
        makeObservable(this, {
            searchData: observable,
            searchResult: observable,
            isLoading: observable,
            isError: observable,
            errors: observable,
            defaultAirportFrom: observable,
            defaultAirportTo: observable,
            currentFlight: observable,
            isDefaultDataLoaded: computed,
            ticketsSearchResult: observable,
            setTicketsSearchResult: action,
            setSearchResult: action,
            setSearchData: action,
            setIsLoading: action,
            setIsError: action,
            setErrors: action,
            resetAll: action,
            resetSeachForm: action,
            resetSearchState: action,
            search: action,
            getCurrentFlight: action,
            resetCurrentFlight: action
        });
        this.searchData = this.getDefaultSearchData();
        reaction(
            () => this.searchResult,
            () => {
                this.parseTicketsSearchData();
            }
        );
    }

    setSearchData(data: Partial<IFlightSearchData>) {
        this.searchData = {...this.searchData, ...data};
    }

    setIsLoading(isLoading: boolean) {
        this.isLoading = isLoading;
    }

    setErrors(errors: (CommonError | APIError)[]) {
        this.errors = errors;
    }

    setIsError(isError: boolean) {
        this.isError = isError;
    }

    getUrlParams(): URLSearchParams {
        const params = createSearchParams();

        if (this.searchData.from) {
            params.set("from", this.searchData.from.iata);
        }

        if (this.searchData.to) {
            params.set("to", this.searchData.to.iata);
        }

        if (this.searchData.startDate) {
            params.set("startDate", this.searchData.startDate);
        }

        if (this.searchData.endDate) {
            params.set("endDate", this.searchData.endDate);
        }

        if (this.searchData.passengers) {
            params.set("passengers", String(this.searchData.passengers));
        }

        return params;
    }

    async parseUrlParams(params: URLSearchParams) {
        const from = params.get("from");
        const to = params.get("to");
        const startDate = params.get("startDate");
        const endDate = params.get("endDate");
        const passengers = params.get("passengers");

        const searchData: Partial<IFlightSearchData> = {};

        if (from) {
            searchData.from = await this.getAirportByIATACode(from);
        }

        if (to) {
            searchData.to = await this.getAirportByIATACode(to);
        }

        if (startDate) {
            searchData.startDate = startDate;
        }

        if (endDate) {
            searchData.endDate = endDate;
        }

        if (passengers) {
            searchData.passengers = Number(passengers);
        }

        runInAction(() => {
            this.searchData = searchData;
        });
    }

    private getApiParams(): IFlightSearchParams {
        if (
            !this.searchData.from?.iata ||
            !this.searchData.to?.iata ||
            !this.searchData.startDate
        ) {
            throw new Error("Mandatory search parameters are missing");
        }

        return {
            from: this.searchData.from.iata,
            to: this.searchData.to.iata,
            startDate: this.searchData.startDate,
            ...(this.searchData.endDate && {
                endDate: this.searchData.endDate
            }),
            passengers: Number(this.searchData.passengers) || 1
        };
    }

    async search() {
        this.isLoading = true;
        this.setIsError(false);
        this.errors = [];
        const errors: (CommonError | APIError)[] = [];

        try {
            const params = this.getApiParams();

            const results = await API.searchFlights(params);
            runInAction(() => {
                if (results.data && results.errors.length === 0) {
                    this.setSearchResult(results.data);
                } else {
                    errors.push(...results.errors);
                }
            });
        } catch (error) {
            runInAction(() => {
                errors.push(handleErrorException(error));
            });
        } finally {
            runInAction(() => {
                this.setErrors(errors);
                if (errors && errors.length) {
                    this.setIsError(true);
                    this.setSearchResult([]);
                }
                this.isLoading = false;
            });
        }
    }

    async initializeDefaultAirports() {
        const {referenceStore} = this.rootStore;

        await when(() => referenceStore.airportsLoaded);

        runInAction(() => {
            this.defaultAirportFrom = referenceStore.airports.find(
                a => a.iata === FLIGHT_SEARCH_CONSTANTS.DEFAULT_FROM_IATA
            );
            this.defaultAirportTo = referenceStore.airports.find(
                a => a.iata === FLIGHT_SEARCH_CONSTANTS.DEFAULT_TO_IATA
            );

            this.searchData.from = this.defaultAirportFrom;
            this.searchData.to = this.defaultAirportTo;
        });
    }

    private getDefaultSearchData(): IFlightSearchData {
        const defaultSearchData: IFlightSearchData = {
            passengers: FLIGHT_SEARCH_CONSTANTS.DEFAULT_PASSENGERS,
            startDate: FLIGHT_SEARCH_CONSTANTS.DEFAULT_START_DATE,
            endDate: FLIGHT_SEARCH_CONSTANTS.DEFAULT_END_DATE
        };

        if (this.defaultAirportFrom) {
            defaultSearchData.from = this.defaultAirportFrom;
        }

        if (this.defaultAirportTo) {
            defaultSearchData.to = this.defaultAirportTo;
        }

        return defaultSearchData;
    }

    get isDefaultDataLoaded() {
        return this.defaultAirportFrom && this.defaultAirportTo;
    }

    resetSeachForm() {
        this.searchData = this.getDefaultSearchData();
    }

    resetSearchState() {
        this.isLoading = false;
        this.errors = [];
        this.setIsError(false);
    }

    resetAll() {
        this.resetSeachForm();
        this.resetSearchState();
        this.setSearchResult([]);
        this.setTicketsSearchResult([]);
    }

    public async setSearchResult(data: FlightData[]) {
        this.searchResult = data;
    }

    protected async parseTicketData(flightData: FlightData): Promise<TicketData> {
        const id = flightData.id;
        const date = flightData.available_from;
        const price = flightData.price;
        const currency = flightData.currency;
        const departureTime = flightData.departure_time;
        const arrivalTime = flightData.arrival_time;
        const flightTime = flightData.duration;
        const seats = flightData.seats;

        const chips: ChipData[] = [];

        const fromAirport = await this.getAirportByIATACode(flightData.from_airport);
        const toAirport = await this.getAirportByIATACode(flightData.to_airport);

        const flightRouteData: FlightRouteData = {
            from: fromAirport || DEFAULT_AIRPORT,
            to: toAirport || DEFAULT_AIRPORT,
            departureTime: convertTimeToAmPmFormat(departureTime) || "00:00 AM",
            arrivalTime: convertTimeToAmPmFormat(arrivalTime) || "00:00 PM",
            flightTime: formatDuration(flightTime) || "0m"
        };

        const aircraftData: AircraftData = {
            name: flightData.aircraft_type,
            image: flightData.exterior_photos[0] || flightData.interior_photos[0] || "", // TODO add default
            description: flightData.aircraft_description || "Description",
            exteriorPhotos: flightData.exterior_photos,
            interiorPhotos: flightData.interior_photos
        };

        return {id, chips, price, currency, date, flightRouteData, seats, aircraftData};
    }

    protected async parseTicketsSearchData(data: FlightData[] = this.searchResult) {
        const ticketsData: TicketData[] = [];

        for (let i = 0; i < data.length; i++) {
            const ticketData = await this.parseTicketData(data[i]);
            ticketsData.push(ticketData);
        }

        const ticketsSearchResult = groupTicketsByDate(ticketsData);

        this.setTicketsSearchResult(ticketsSearchResult);
    }

    private async getAirportByIATACode(iataCode: string): Promise<AirportData | undefined> {
        // console.log("iataCode:", iataCode);
        const referenceStore = this.rootStore.referenceStore;
        // console.log("referenceStore:", referenceStore.airports);

        if (referenceStore.airportsLoaded) {
            return referenceStore.airports.find(airport => airport.iata === iataCode);
        }

        if (referenceStore.airportsLoadingError) {
            return undefined;
        }

        try {
            await when(() => referenceStore.airportsLoaded);
            return referenceStore.airports.find(airport => airport.iata === iataCode);
        } catch (error) {
            console.error("Error waiting for airports to load:", error);
            return undefined;
        }
    }

    public setTicketsSearchResult(data: TicketsSearchResult[]) {
        this.ticketsSearchResult = data;
    }

    // Метод для получения билета из API
    async getCurrentFlight(flightId: string) {
        // Сначала проверяем, есть ли билет в текущих результатах поиска
        const existingFlight = this.ticketsSearchResult
            .flatMap(group => group.data)
            .find(ticket => ticket.id === flightId);

        if (existingFlight) {
            runInAction(() => {
                this.currentFlight.data = existingFlight;
                this.currentFlight.isLoading = false;
                this.currentFlight.errors = [];
            });
            return;
        }

        // Если билет не найден в результатах, делаем запрос к API
        runInAction(() => {
            this.currentFlight.isLoading = true;
            this.currentFlight.errors = [];
        });

        const errors: APIError[] = [];

        try {
            const response = await API.getFlight(flightId);
            runInAction(async () => {
                if (response.data && response.errors.length === 0) {
                    this.currentFlight.data = await this.parseTicketData(response.data);
                } else {
                    errors.push(...response.errors);
                }
                this.currentFlight.errors = errors;
                this.currentFlight.isLoading = false;
            });
        } catch (error) {
            runInAction(() => {
                errors.push(handleErrorException(error));
                this.currentFlight.errors = errors;
                this.currentFlight.isLoading = false;
            });
        }
    }

    resetCurrentFlight() {
        this.currentFlight = {
            isLoading: false,
            errors: []
        };
    }
}
