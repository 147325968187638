export const HomeWelcomeBlock = () => {
    return (
        <div className="welcome-block">
            <h1 className="header1">Reach your destination all over the world.</h1>
            <h3 className="header3">
                Cheapest way to enjoy business class air jet in your journey.
            </h3>
            <img
                className="w-11 welcome-block__image mx-auto"
                src={"../images/jet-xl.png"}
                alt={"Jetsaver"}
            />
        </div>
    );
};
