import {handleUnauthorizedResponse} from "./actions/handleUnauthorizedResponse";

interface RequestOptions extends RequestInit {
    skipUnauthorizedHandler?: boolean;
}

export const createHttpClient = (baseUrl: string) => {
    const handleResponse = async (response: Response, skipUnauthorizedHandler = false) => {
        if (response.status === 401 && !skipUnauthorizedHandler) {
            handleUnauthorizedResponse();
        }
        return response;
    };

    return {
        async get<T>(
            endpoint: string,
            token?: string | null,
            customConfig: RequestOptions = {}
        ): Promise<T> {
            const {skipUnauthorizedHandler, ...config} = customConfig;

            if (!token) {
                throw new Error("No token provided");
            }

            const finalConfig: RequestInit = {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "x-session-token": token
                },
                ...config
            };

            const response = await fetch(`${baseUrl}${endpoint}`, finalConfig);
            await handleResponse(response, skipUnauthorizedHandler);
            return response.json();
        },

        async post<T, D = any>(
            endpoint: string,
            data: D,
            token?: string | null,
            customConfig: RequestOptions = {}
        ): Promise<T> {
            const {skipUnauthorizedHandler, ...config} = customConfig;

            if (!token) {
                throw new Error("No token provided");
            }

            const finalConfig: RequestInit = {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "x-session-token": token
                },
                body: JSON.stringify(data),
                ...config
            };

            const response = await fetch(`${baseUrl}${endpoint}`, finalConfig);
            await handleResponse(response, skipUnauthorizedHandler);
            return response.json();
        },

        async put<T, D = any>(
            endpoint: string,
            data: D,
            token?: string | null,
            customConfig: RequestInit = {}
        ): Promise<T> {
            if (!token) {
                throw new Error("No token provided");
            }

            const config: RequestInit = {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "x-session-token": token
                },
                body: JSON.stringify(data),
                ...customConfig
            };

            const response = await fetch(`${baseUrl}${endpoint}`, config);
            await handleResponse(response);
            return response.json();
        }
    };
};
