import {observer} from "mobx-react";
import {Button} from "primereact/button";
import {useState} from "react";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import {
    passwordLengthRule,
    passwordNumericRule,
    passwordSymbolRule,
    passwordUppercaseRule,
    PASSWWORD_SUGGESTIONS_DICTIONARY,
    REQUIRED_PASSWORD_VALIDATION_RULE,
    VALIDATION_MESSAGES
} from "../../constants";
import {usePasswordSuggestions} from "../../customHooks/usePasswordSuggesions";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {ReactComponent as FormHeaderIconDone} from "../../icons/icon-form-header-done.svg";
import {ReactComponent as FormHeaderIconLock} from "../../icons/icon-form-header-lock.svg";
import {API} from "../../services/ApiService";
import {Header3} from "../common";
import {Field} from "../fieldComponents/FieldComponents";
import {
    PasswordFieldControlled,
    PasswordSuggestionsFooter
} from "../fieldComponents/PasswordWithSuggestion";
import {FormContent, FormFooter, FormHeader, FormHeaderIcon} from "../formComponents";
// import {FormHeaderIcon} from "./components/FormComponents";

interface INewPasswordForm {
    newPassword: string;
    confirmPassword: string;
}

export const NewPasswordForm = observer(() => {
    const [isSuccessfullySubmitted, setIsSuccessfullySubmitted] = useState(false);
    const {translate} = useSafeTranslations();

    const defaultValues = {
        newPassword: "",
        confirmPassword: ""
    };

    const {
        control,
        handleSubmit,
        setError,
        reset,
        watch,
        getValues,
        formState: {errors, isSubmitting}
    } = useForm<INewPasswordForm>({defaultValues});

    const navigate = useNavigate();

    const newPasswordValue = watch("newPassword") || "";
    const confirmPasswordValue = watch("confirmPassword") || "";
    const newPasswordRulles = usePasswordSuggestions(
        newPasswordValue,
        PASSWWORD_SUGGESTIONS_DICTIONARY
    );

    const confirmPasswordRulles = usePasswordSuggestions(
        confirmPasswordValue,
        PASSWWORD_SUGGESTIONS_DICTIONARY
    );

    const handleLoginClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        console.log("login click");
        navigate("/");
    };

    const onSubmit = async (data: INewPasswordForm) => {
        try {
            const res = await API.setNewPass(data.newPassword, data.confirmPassword);
            if (res.data) {
                setIsSuccessfullySubmitted(true);
            }
        } catch (e) {
            // Server error hadling
            console.error(e);
            if (errors.newPassword) {
                setError("newPassword", {
                    type: "server",
                    message: "Something went wrong with new password"
                });
            }

            if (errors.confirmPassword) {
                setError("confirmPassword", {
                    type: "server",
                    message: "Something went wrong with confirmPassword"
                });
            }
        }
    };

    return (
        <form className="form form--new-password" onSubmit={handleSubmit(onSubmit)}>
            <FormHeader className={"align-items-center"}>
                <FormHeaderIcon
                    icon={isSuccessfullySubmitted ? <FormHeaderIconDone /> : <FormHeaderIconLock />}
                />
                <Header3>
                    {isSuccessfullySubmitted
                        ? translate("complete-text")
                        : translate("new-password-form-header-text")}
                </Header3>

                {isSuccessfullySubmitted ? (
                    <div className="content-b3 form-description">
                        {translate("confirm-passsord-success-message")}
                    </div>
                ) : (
                    <></>
                )}
            </FormHeader>
            <FormContent className={"pt-5"}>
                {isSuccessfullySubmitted ? null : (
                    <>
                        <PasswordFieldControlled
                            name={"newPassword"}
                            placeholder={translate("password-text")}
                            disabled={isSubmitting}
                            control={control}
                            rulles={REQUIRED_PASSWORD_VALIDATION_RULE}
                            errors={errors.newPassword}
                            footer={<PasswordSuggestionsFooter rules={newPasswordRulles} />}
                        />
                        <PasswordFieldControlled
                            name={"confirmPassword"}
                            placeholder={translate("confirm-password-text")}
                            disabled={isSubmitting}
                            control={control}
                            rulles={{
                                validate: {
                                    ...passwordLengthRule,
                                    ...passwordUppercaseRule,
                                    ...passwordNumericRule,
                                    ...passwordSymbolRule,
                                    isTheSamePassword: (value: string) => {
                                        const newPassValue = getValues("newPassword");
                                        const confirmValue = value;
                                        return (
                                            (!!newPassValue && confirmValue === newPassValue) ||
                                            VALIDATION_MESSAGES.PASSWORDS_MUST_MATCH
                                        );
                                    }
                                }
                            }}
                            errors={errors.confirmPassword}
                            footer={<PasswordSuggestionsFooter rules={confirmPasswordRulles} />}
                        />
                    </>
                )}
            </FormContent>
            <FormFooter>
                <Field>
                    {isSuccessfullySubmitted ? (
                        <Button
                            name={"login"}
                            type={"button"}
                            className="main-button main-button--w-full justify-content-center"
                            label={translate("login-text")}
                            onClick={handleLoginClick}
                        />
                    ) : (
                        <Button
                            name={"submit"}
                            type={"submit"}
                            className="main-button main-button--w-full justify-content-center"
                            label={translate("set-password-button-text")}
                            disabled={isSubmitting}
                        />
                    )}
                </Field>
            </FormFooter>
        </form>
    );
});
