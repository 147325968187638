import {ButtonProps} from "primereact/button";
import {MainButton, SecondaryButton} from "../common/Buttons";
import {classNames} from "primereact/utils";
import {useSafeTranslations} from "../../customHooks/useSafeTranslations";
import {ReactComponent as LogoApple} from "../../icons/icon-login-apple.svg";
import {ReactComponent as LogoGoogle} from "../../icons/icon-login-google.svg";

export const GoogleLoginButton = (props: ButtonProps) => {
    const {translate} = useSafeTranslations();
    return (
        <MainButton
            {...props}
            icon={<LogoGoogle />}
            className={classNames("main-button--w-full justify-content-center", props.className)}
        >
            <span className="ml-2">{translate("google-login-btn-text")}</span>
        </MainButton>
    );
};

export const AppleLoginButton = (props: ButtonProps) => {
    const {translate} = useSafeTranslations();
    return (
        <SecondaryButton
            {...props}
            icon={<LogoApple />}
            className={classNames(
                "secondary-button--w-full justify-content-center",
                props.className
            )}
        >
            <span className="ml-2">{translate("apple-login-btn-text")}</span>
        </SecondaryButton>
    );
};

export const ContinueLoginButton = (props: ButtonProps) => {
    const {translate} = useSafeTranslations();
    return (
        <SecondaryButton
            {...props}
            type={"submit"}
            label={translate("continue-text")}
            className={classNames("secondary-button--w-full", props.className)}
        />
    );
};
