import React from "react";
import {Link} from "react-router-dom";
import {ReactComponent as LogoSvg} from "./logo.svg";

const Logo: React.FC = () => {
    return (
        <div className="logo">
            <Link to="/">
                <LogoSvg />
            </Link>
        </div>
    );
};

export default Logo;
